class Busca {

	constructor(inputs, id_render_busca){

		// Primeiros Atributos, tudo o que a classe for receber
		this.inputs = inputs;
		this.id_render_busca = id_render_busca;
		this.render_element = document.getElementById(this.id_render_busca);
		this.rules(); // Valida esses parametros

		// Atributos internos da  classe
		this.payload = window.location.href.split('payload=')[1];
		this.valores = new Object();
		this.endpoint = '/imoveis/buscar/?payload=';
		this.endpoint_back = '';

		this.selectmultiples = [];
		this.componentsinputs = [];
		this.instancia_nome;

		for(var i in inputs){
			this.valores[inputs[i]] = '';
		}
		
		/* beforeunload EVENT  */
		Boss.evts.add('popstate', window, function(evts){

			evts.cancelBubble = true;

			if(evts.stopPropagation){
				evts.stopPropagation();
			}

			if(evts.preventDefault){
				evts.preventDefault();
			}

			var payload = window.location.href.split('payload=')[1];

			if(typeof(payloadBusca) !== 'undefined'){
				payloadBusca.setPayload = payload;
				payloadBusca.send(true);
			}
		});
	}

	addComponentsinputs(input){
		this.componentsinputs.push(input);
	}

	addSelectmultiple(selectmultiples){
		this.selectmultiples.push(selectmultiples);
	}

	// Função que valida os parâmetros e da classe
	rules(){

		if(production === true){
			return false;
		}

		if(typeof(this.id_render_busca) == 'undefined'){
			console.warn('Ops, vou precisar que você informe o ID do elemento para renderizar a busca.');
			return false;
		}

		if(!this.render_element){
			console.warn('Ops, parece que o ID elemento que você informou para renderizar a busca não existe.');
			return false;
		}

		if(typeof(this.inputs) == 'undefined'){
			console.warn('Ops, vou precisar que você informe os inputs. É uma lista simples ["dor", "vag", "cod"].');
			return false;
		}
	}

	add(valor, idElemento){

		this.setValueInput(valor, idElemento);

		this.mountPayload();
	}

	set setPayload(payload){
		this.payload = payload;
		wqddwqgqioq.href = this.payload;
	}

	setValueInput(valor, idElemento){

		if(typeof(this.valores[idElemento]) !== 'undefined'){

			// Se for um Array || lista
			if(valor instanceof Array){

				this.valores[idElemento] = valor.join(',');
			}else{
			// Se for uma string
				this.valores[idElemento] = valor;
			}
		}

	}

	adicionaValoresPayload(){
		if(typeof(payloadBusca) != 'undefined'){
			var temp = [];
			for (let i = 0; i < payloadBusca.selectmultiples.length; i++) {
				let data = payloadBusca.selectmultiples[i];
				
				temp[i] = [payloadBusca.selectmultiples[i].values,payloadBusca.selectmultiples[i].alias];
				var temp2 = '';
				if(payloadBusca.selectmultiples[i].values != ''){

					for(var y in payloadBusca.selectmultiples[i].values){
						temp2 += payloadBusca.selectmultiples[i].values[y]+',';
					}
					payloadBusca.valores[payloadBusca.selectmultiples[i].alias] = temp2;
				}
			}

			for (let i = 0; i < payloadBusca.componentsinputs.length; i++) {
				let data = payloadBusca.componentsinputs[i];
				
				temp[i] = [payloadBusca.componentsinputs[i].value,payloadBusca.componentsinputs[i].id];
				var temp2 = '';
				if(payloadBusca.componentsinputs[i].value != ''){
					if(payloadBusca.componentsinputs[i]){
						payloadBusca.valores[payloadBusca.componentsinputs[i].id] = payloadBusca.componentsinputs[i].value;
					}
				}
			}
		}
	}

	// Monta o PayLoad
	mountPayload(){

		var temp = '';
		for(var i in this.valores){
			temp += '&' + i + '=' + this.valores[i];
		}
		
		this.payload = temp;
	}

	static checkboxValues(filtros, elemento_pai_filtros){

		if(!elemento_pai_filtros){
			return false;
		}

		var inputs = elemento_pai_filtros.querySelectorAll('input[type="checkbox"]');

		var infs = filtros.inf;

		if(infs !== false){

			for(var i = 0; i < inputs.length; i++){

				inputs[i].checked = false;
				if(infs[inputs[i].id.replace('comp-', '')]){
					inputs[i].checked = true;
				}
			}
		}
	}

	static selectmultiplesValues(filtros, selectmultiples){

		for(var i = 0; i < selectmultiples.length; i++){
			var valor = [];
			if(filtros[selectmultiples[i]['alias']] !== false){
				valor = filtros[selectmultiples[i]['alias']];
			}

			selectmultiples[i].setValues(valor);
		}
	}
	
	static componentinputsValues(filtros, componentsinputs){

		function isDict(v){
			return typeof v==='object' && v!==null && !(v instanceof Array) && !(v instanceof Date);
		}

		for(var i = componentsinputs.length - 1; i >= 0; i--){

			if(componentsinputs[i] && filtros[componentsinputs[i].id] !== false){
				var valorInput = '';

				if(isDict(filtros[componentsinputs[i].id])){
					for(var x in filtros[componentsinputs[i].id]){
						valorInput += filtros[componentsinputs[i].id][x] + ',';
						
					}
					
					if(valorInput.substring(valorInput.length-1) == ","){
						valorInput = valorInput.replace(' ','');
						valorInput = valorInput.substr(0, valorInput.length - 1);
					}

				}else{
					valorInput = filtros[componentsinputs[i].id];
				}

				componentsinputs[i].value = valorInput;
			}
		}
	}

	static tituloBusca(titulo_busca_id, quantidade, href = false){
		let quantidadeImoveis = quantidade;
		let titulo_busca = Boss.getById(titulo_busca_id);

		titulo_busca.innerHTML = '';
		if(titulo_busca){

			titulo_busca.innerHTML = quantidade;

			/* if(quantidadeImoveis == 0){
				titulo_busca.innerHTML = 'Nenhum imóvel foi encontrado.';
				document.getElementById('render-busca').innerHTML = '';
			}else{

				var encontrado = 'encontrado';
				var imovel = 'imóvel';
				if(quantidadeImoveis > 1){
					encontrado = 'encontrados';
					imovel = 'imóveis';
				}
				titulo_busca.innerHTML = '<strong> ' + quantidadeImoveis + ' '+imovel+'</strong> '+encontrado+'.' 
			} */
		}
	}

	static positionAtTop(elemento){
		let posicao = 0;
		if(elemento.offsetParent){
			do{
				posicao += elemento.offsetTop;
			} while (elemento = elemento.offsetParent);
		}
		return posicao;
	}

	static request(endpoint, pushstate, selectmultiples, componentsinputs, href = false){

		let htmlPreLoader = `
			<style>
				.animationLoading{

					width: calc(33.3333% - 30px);
					display: inline-block;
					vertical-align: top;
					background: #fff;
					border: 1px solid;
					border-color: #e5e6e9;
					border-radius: 3px;	
					padding: 12px;
					position: relative;
					box-sizing: border-box;
					height: 400px;
					margin: 5px;
				}
				@keyframes animate {
					0% {
						background-position: -468px 0
					}
					100% {
						background-position: 468px 0
					}
				
				}
				
				#container{
					width:33.3333%;
					display: inline-block;
					vertical-align: top;
				}
				#one,#two, #two2,#three,#four,#five, #fivei, #fivei2, #fivei3,#five2,#five3,#six,#six2
				{
				position: absolute;
				background-color: #CCC;
				height: 6px;
				animation-name: animate; 
				animation-duration: 2s; 
				animation-iteration-count: infinite;
				animation-timing-function: linear;	  
				background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
				background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
				background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
				-webkit-background-size: 800px 104px; 
				
				padding: 1rem;
				box-sizing: border-box;
				}
				
				:root {
				--mini_space: 11rem;
				}
				
				#one{  
				left: 1rem; 
				width: calc(100% - 2rem);
				height: var(--mini_space);
				}
				
				#two{
				left:1rem; 
				width: 60%;
				top: calc(1.5rem + var(--mini_space));
				height: 1.3rem;
				padding: 0;
				}
				
				#two2{
				left:1rem; 
				width: 35%;
				top: calc(3rem + var(--mini_space));
				height: 1.3rem;
				padding: 0;
				}
				
				#three{
				right:1rem; 
				width: 10%;
				top: calc(1.5rem + var(--mini_space));
				height: 2rem;
				padding: 0;
				}
				
				#four{
				left:1rem; 
				width: 80%;
				top: calc(5rem + var(--mini_space));
				height: .8rem;
				padding: 0;
				}
				
				#five{
				left:8%; 
				width: 20%;
				top: calc(8.5rem + var(--mini_space));
				height: .7rem;
				padding: 0;
				}
				#fivei{
				left:13%; 
				width: 10%;
				top: calc(7rem + var(--mini_space));
				height: 1rem;
				padding: 0;
				}
				
				#fivei2{
				left: 45%; 
				width: 10%;
				top: calc(7rem + var(--mini_space));
				height: 1rem;
				padding: 0;
				}
				#fivei3{
				right: 13%; 
				width: 10%;
				top: calc(7rem + var(--mini_space));
				height: 1rem;
				padding: 0;
				}
				#five2{
				left:50%; 
				width: 20%;
				top: calc(8.5rem + var(--mini_space));
				height: .7rem;
				padding: 0;
				transform: translate(-50%);
				}
				#five3{
				right:8%; 
				width: 20%;
				top: calc(8.5rem + var(--mini_space));
				height: .7rem;
				padding: 0;
				}
				
				#six{
				left:1rem; 
				width: 35%;
				top: calc(11rem + var(--mini_space));;
				height: 2rem;
				padding: 0;
				}
				
				#six2{
				right:1rem; 
				width: 20%;
				top: calc(11.5rem + var(--mini_space));;
				height: 1rem;
				padding: 0;
				}
                @media screen and (max-width: 1023px) {
                    .animationLoading{
					    width: calc(100% - 30px) !important;
                    }
                }
			</style>
			<div class="box-laterais-1">
				<div class="animationLoading">
					<div id="container">
						<div id="one"></div> 
						<div id="two"></div> 
						<div id="two2"></div>
						<div id="three"></div>
						<div id="four"></div>
						<div id="five"></div>
						<div id="fivei"></div>
						<div id="fivei2"></div>
						<div id="fivei3"></div>
						<div id="five2"></div>
						<div id="five3"></div>
						<div id="six"></div>
						<div id="six2"></div>
					</div>
				</div><div class="animationLoading">
					<div id="container">
						<div id="one"></div> 
						<div id="two"></div> 
						<div id="two2"></div>
						<div id="three"></div>
						<div id="four"></div>
						<div id="five"></div>
						<div id="fivei"></div>
						<div id="fivei2"></div>
						<div id="fivei3"></div>
						<div id="five2"></div>
						<div id="five3"></div>
						<div id="six"></div>
						<div id="six2"></div>
					</div>
				</div>
				<div class="animationLoading">
					<div id="container">
						<div id="one"></div> 
						<div id="two"></div> 
						<div id="two2"></div>
						<div id="three"></div>
						<div id="four"></div>
						<div id="five"></div>
						<div id="fivei"></div>
						<div id="fivei2"></div>
						<div id="fivei3"></div>
						<div id="five2"></div>
						<div id="five3"></div>
						<div id="six"></div>
						<div id="six2"></div>
					</div>
				</div><div class="animationLoading">
				<div id="container">
					<div id="one"></div> 
					<div id="two"></div> 
					<div id="two2"></div>
					<div id="three"></div>
					<div id="four"></div>
					<div id="five"></div>
					<div id="fivei"></div>
					<div id="fivei2"></div>
					<div id="fivei3"></div>
					<div id="five2"></div>
					<div id="five3"></div>
					<div id="six"></div>
					<div id="six2"></div>
				</div>
			</div><div class="animationLoading">
				<div id="container">
					<div id="one"></div> 
					<div id="two"></div> 
					<div id="two2"></div>
					<div id="three"></div>
					<div id="four"></div>
					<div id="five"></div>
					<div id="fivei"></div>
					<div id="fivei2"></div>
					<div id="fivei3"></div>
					<div id="five2"></div>
					<div id="five3"></div>
					<div id="six"></div>
					<div id="six2"></div>
				</div>
			</div>
			<div class="animationLoading">
				<div id="container">
					<div id="one"></div> 
					<div id="two"></div> 
					<div id="two2"></div>
					<div id="three"></div>
					<div id="four"></div>
					<div id="five"></div>
					<div id="fivei"></div>
					<div id="fivei2"></div>
					<div id="fivei3"></div>
					<div id="five2"></div>
					<div id="five3"></div>
					<div id="six"></div>
					<div id="six2"></div>
				</div>
			</div>
			</div>
			</div>
		`;

		if(document.getElementById('titulo_busca')){
			document.getElementById('titulo_busca').innerHTML = 'Aguarde... <i class="icl ic-spinner-third rotating"></i> ';
		}
		/* if(document.getElementById('render-busca')){
			document.getElementById('render-busca').innerHTML = htmlPreLoader;
		} */
		
		Boss.ajax({
			'url': endpoint,
			'data': {'push': 'push'},
			'dataType': 'json',
			'done': function(rtn){

				for(var i in rtn.imoveisExibidos){
					imoveis_ja_exibidos.push(rtn.imoveisExibidos[i]);
				}

				// Atualiza o título da Busca
				Busca.tituloBusca('titulo_busca', rtn.titulo, href);

				/* Atualiza os campos do SelectMultiple */
				Busca.selectmultiplesValues(rtn.filtros, selectmultiples);

				/* Atualiza os campos do Input */
				Busca.componentinputsValues(rtn.filtros, componentsinputs);

				/* Atualiza os campos Checkbox */
				Busca.checkboxValues(rtn.filtros, Boss.getById('busca-filtros'));

				if(!pushstate){
					history.pushState({}, '', endpoint);
				}

				if(document.getElementById('render-busca')){
					document.getElementById('render-busca').innerHTML = rtn.html;
				}

				/* if(typeof(Paginacao) !== 'undefined'){
					Paginacao.setConfiguracao = rtn.paginacao;
					Paginacao.render();


					var sc = Busca.positionAtTop(document.getElementById('section-imoveis')); */
					/* if(typeof(smoothScroll) !== 'undefined'){
						smoothScroll.smoothScrollTo(0, sc, 1500);
					}else{
						window.scrollTo(0, sc);
					} */
				//}
			},
			error: function(rtn){
				Boss.warning({message: 'Falha ao enviar, tente novamente em alguns segundos!'});
			}
		});
	}

	// Função que faz o request para a busca
	// DEPOIS que enviar o payload, fechar a busca && filtro
	// O parametro HREF, é para os casos em que vai utilizar a busca mas não está no controlador da busca aonde fica o render.
	// Isso quer dizer que não podemos utilizar o pushstate porque no retorno do ajax não tem aonde ele exibir os resultados.
	// No formado do site SEM PUSH HISTORY, não é possível alterar a pagina sem um refresh, sendo assim perdendo a instancia da busca.
	// Quando for utilizar o método send() FORA da busca em outro controlador qualquer.. Usa-se send(true, true) para não fazer o request
	// E simplesmente redimencionar para a página da busca com os filtros prontos.
	send(pushstate = false, href = false, time = 0){

		var endpoint = this.endpoint + '' + this.payload;
		wqddwqgqioq.href = endpoint;
		var selectmultiples = this.selectmultiples;
		var componentsinputs = this.componentsinputs;

		if(href){
			history.pushState({}, '', endpoint);


			return false;
		}

		Boss.delayPersistent2(function(){
			Busca.request(endpoint, pushstate, selectmultiples, componentsinputs, href);
		}, time, 'request-ajax');
	}
}